<template>
  <div class="container">
    <div class="content">
      <div class="item">
        <img src="../../assets/create.png" @click="toView('/create')" />
        <div class="btn" @click="toView('/create')">Create A New Entry</div>
        <div class="intro">If you are the leader of your project, and your adviser or teammate has never created an entry for your project. You should create a new entry from here. </div>
      </div>
      <div class="item">
        <img src="../../assets/add.png" @click="toView('/detail')" />
        <div class="btn" @click="toView('/detail')">Join An Existing Entry</div>
        <div class="intro">If your adviser or team member has created an entry for you project already, you need to join that existing entry from here with a joining code.</div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from '../../api/index'
import '../../assets/common/font.css'
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {
    toView (route) {
      this.$router.push({
        path: route,
        query: {
          program: this.$route.query.program
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/applyBack.png');
  background-size: 110% 120%;
  background-position: 10% 10%;
    font-family: "DDINAlternateRegular";
  .content {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    background: #fff;
    display: flex;
    .item {
      width: 50%;
      img {
        display: block;
        width: 371px;
        margin: 0 auto;
        margin-top: 20%;
        cursor: pointer;
      }
      .intro{
        width: 371px;
        margin: 0 auto;
        margin-top: 50px;
        font-family: "DDINAlternateRegular";
        font-weight: 500;
        color: #0E4890;
        line-height: 32px;
      }
      .btn {
        width: 50%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #ffb414;
        color: #fff;
        font-weight: bold;
        border-radius: 26px 26px 26px 26px;
        margin: 0 auto;
        margin-top: 25px;
        cursor: pointer;
      }
    }
  }
}
</style>
